import React, { useEffect, useRef, useState } from "react"
import { graphql } from "gatsby"

import AudioPlayer, { RHAP_UI } from "react-h5-audio-player"

import * as soundStyles from "./sound.module.sass"

import PlaySVG from "../assets/images/player/playButton.svg"
import CircleSVG from "../assets/images/circle.svg"
import SquareSVG from "../assets/images/square.svg"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const PlayButton = (props) => {
  let completePlayButton = <div className={soundStyles.playButton} onClick={playHandler}>
    {/*<CircleSVG className={soundStyles.circle} />*/}
    <PlaySVG className={soundStyles.playIcon} />
  </div>


  const [playPauseButton, setPlayPauseButton] = useState(completePlayButton)
  const [isPlaying, setIsPlaying] = useState(false)

  useEffect(() => {
    if (props.currentSoundIndex == props.songIndex) {
      if (!isPlaying) {
        setPlayPauseButton(<div className={soundStyles.playButton}></div>)
        setIsPlaying(true)
      }
    } else if (isPlaying) {
      setPlayPauseButton(completePlayButton)
      setIsPlaying(false)
    }
  })

  function playHandler() {
    props.setCurrentSoundIndex(props.songIndex)
    if (!isPlaying) {
      props.audioPlayerRef.current.audio.current.play()
      setPlayPauseButton(<div className={soundStyles.playButton}></div>)
      setIsPlaying(true)
    }

  }

  return (
    <div className={soundStyles.artworkContainer}>
      <SquareSVG className={(props.songIndex == props.currentSoundIndex ? `${soundStyles.songArtworkSelector} enter` : `${soundStyles.songArtworkSelector} exit`)}></SquareSVG>
      <GatsbyImage alt={"Song Artwork"} image={props.songArtwork} className={soundStyles.songArtwork} onClick={playHandler}></GatsbyImage>
      {playPauseButton}
    </div>
  )
}
export default PlayButton
