// extracted by mini-css-extract-plugin
export const pageContainer = "room-module--page-container--1UuQf";
export const overflowHider = "room-module--overflow-hider--3JQZu";
export const gradientOverlay = "room-module--gradient-overlay--2qLYG";
export const roomContainer = "room-module--room-container--2bEyQ";
export const roomImage = "room-module--room-image--E2MYE";
export const roomStorage = "room-module--room-storage--262nl";
export const writingImage = "room-module--writing-image--XE8Rw";
export const linksContainer = "room-module--links-container--1R8Q1";
export const roomLink = "room-module--room-link--1Dry_";
export const roomLinkImageFiddle = "room-module--room-link-image-fiddle--3gCx7";
export const instagram = "room-module--instagram--KSk3Z";
export const facebook = "room-module--facebook--2ueUU";
export const youtube = "room-module--youtube--1BQ1A";