import React, { useEffect, useRef, useState } from "react"
import Link from "gatsby-link"

import * as roomStyles from "./room.module.sass"

import RoomLoungeSVG from "../assets/images/room/room-lounge.svg"
import RoomCloakroomSVG from "../assets/images/room/room-cloakroom.svg"
import RoomStorageSVG from "../assets/images/room/room-storage-new.svg"
import RoomStorageOLDSVG from "../assets/images/room/room-storage.svg"
import RoomBikeSVG from "../assets/images/room/room-bike.svg"
import InstagramSVG from "../assets/images/room/instagram.svg"
import FacebookSVG from "../assets/images/room/facebook.svg"
import YoutubeSVG from "../assets/images/room/youtube.svg"

import WritingSonSVG from "../assets/images/room/writing-son.svg"
import WritingVestiaireSVG from "../assets/images/room/writing-vestiaire.svg"
import WritingArchiveSVG from "../assets/images/room/writing-archive.svg"
import WritingContactSVG from "../assets/images/room/writing-contact.svg"
import WritingECCSVG from "../assets/images/room/writing-ECC.svg"

const Room = (props) => {

  const [pageWidth, setPageWidth] = useState(0)
  useEffect(() => {
    setPageWidth(window.innerWidth)
    window.addEventListener("resize", () => {
      setPageWidth(window.innerWidth)
    })
    return () => {
      window.removeEventListener("resize", () => {
      })
    }
  }, [])


  let roomElements = []
  let roomWritings = []
  let roomLinks = []


  let roomLounge = <div key={0} className={`${roomStyles.roomImage}`}>
    <RoomLoungeSVG />
  </div>
  roomElements.push(roomLounge)
  let roomCloakroom = <div key={1} className={`${roomStyles.roomImage}`}>
    <RoomCloakroomSVG />
  </div>
  roomElements.push(roomCloakroom)
  let roomStorage = <div key={2} className={`${roomStyles.roomImage}`}>
    <RoomStorageSVG className={roomStyles.roomStorage} />
  </div>
  roomElements.push(roomStorage)
  let roomBike = <div key={3} className={`${roomStyles.roomImage}`}>
    <RoomBikeSVG />
  </div>
  roomElements.push(roomBike)
  let instagram = <a key={4} href={"https://www.instagram.com/troisiemechambre/"} target={"_blank"} className={`${roomStyles.instagram}`}>
    <InstagramSVG />
  </a>
  roomElements.push(instagram)
  let facebook = <a key={5} href={"https://www.facebook.com/3ème-Chambre-112273640898049"} target={"_blank"} className={`${roomStyles.facebook}`}>
    <FacebookSVG />
  </a>
  roomElements.push(facebook)
  let youtube = <a key={6} href={"https://www.youtube.com/channel/UC0AzJv6tkdDfTjpuZBtsdwQ"} target={"_blank"} className={`${roomStyles.youtube}`}>
  <YoutubeSVG />
  </a>
  roomElements.push(youtube)

  let writingSonRef = useRef()
  let writingSon =  <div ref={writingSonRef} key={0} className= {roomStyles.writingImage}>
  {/* let writingSon =  <div ref={writingSonRef} key={0} className= {pageWidth >= 1024 ? `${roomStyles.writingImage}` : `${roomStyles.writingImage} enter`}> */}

    <WritingSonSVG />
  </div>
  roomWritings.push(writingSon)
  let writingVestiaireRef = useRef()
  let writingVestiaire = <div ref={writingVestiaireRef} key={1} className={roomStyles.writingImage}>
  {/* let writingVestiaire = <div ref={writingVestiaireRef} key={1} className={pageWidth >= 1024 ? `${roomStyles.writingImage}` : `${roomStyles.writingImage} enter`}> */}
  
    <WritingVestiaireSVG />
  </div>
  roomWritings.push(writingVestiaire)
  let writingArchiveRef = useRef()
  let writingArchive = <div ref={writingArchiveRef} key={2} className={roomStyles.writingImage}>
    <WritingContactSVG />
  </div>
  roomWritings.push(writingArchive)
  let writingECCRef = useRef()
  let writingECC = <div ref={writingECCRef} key={3} className={roomStyles.writingImage}>
  {/* let writingECC = <div ref={writingECCRef} key={3} className={pageWidth >= 1024 ? `${roomStyles.writingImage}` : `${roomStyles.writingImage} enter`}> */}

    <WritingECCSVG />
  </div>
  roomWritings.push(writingECC)


  let sonLinkZone = { left: `25%`, top: `70%`, width: `45%`, height: `30%` }
  let sonLink = <Link key={0} to={"/sound"} className={roomStyles.roomLink} style={sonLinkZone} onMouseOver={() => fadeWritingIn(writingSonRef)} onMouseOut={() => fadeWritingOut(writingSonRef)} onClick={props.exitTransition} />
  roomLinks.push(sonLink)
  let vestiaireLinkZone = { left: `0%`, top: `40%`, width: `25%`, height: `60%` }
  let vestiaireLink = <Link key={1} to={"/cloakroom"} className={roomStyles.roomLink} style={vestiaireLinkZone} onMouseOver={() => fadeWritingIn(writingVestiaireRef)} onMouseOut={() => fadeWritingOut(writingVestiaireRef)} onClick={props.exitTransition} />
  roomLinks.push(vestiaireLink)
  let archiveLinkZone = { left: `70%`, top: `60%`, width: `30%`, height: `40%` }
  let archiveLink = <Link key={2} to={"/contact"} className={roomStyles.roomLink} style={archiveLinkZone} onMouseOver={() => fadeWritingIn(writingArchiveRef)} onMouseOut={() => fadeWritingOut(writingArchiveRef)} />
  roomLinks.push(archiveLink)
  let ECCLinkZone = { left: `35%`, top: `50%`, width: `25%`, height: `25%` }
  // let ECCLink = <Link key={3} to={"https://www.instagram.com/equipecyclisteceleste/"} className={roomStyles.roomLink} style={ECCLinkZone} onMouseOver={() => fadeWritingIn(writingECCRef)} onMouseOut={() => fadeWritingOut(writingECCRef)} onClick={props.exitTransition} />
  let ECCLink = <a key={3} target={"_blank"} href={"https://www.instagram.com/equipecyclisteceleste/"} className={roomStyles.roomLink} style={ECCLinkZone} onMouseOver={() => fadeWritingIn(writingECCRef)} onMouseOut={() => fadeWritingOut(writingECCRef)} onClick={props.exitTransition}></a>
  roomLinks.push(ECCLink)

  function fadeWritingIn(ref) {
    ref.current.className = `${roomStyles.writingImage} enter`
  }

  function fadeWritingOut(ref) {
    ref.current.className = `${roomStyles.writingImage} exit`
  }

  // useEffect(()=>{
  //     try{
  //       props.audioPlayerRef.current.audio.current.play()
  //     }catch(error){
  //       // console.log(error)
  //     }
  //   })
  return (
    <div className={roomStyles.pageContainer}>
      <div className={roomStyles.overflowHider}>
        <div className={roomStyles.gradientOverlay}></div>
        <div className={roomStyles.roomContainer}>
          {roomElements}
          <div className={roomStyles.linksContainer}>
            {/*fiddle to get the linksContainer on the right size*/}
            <RoomLoungeSVG className={roomStyles.roomLinkImageFiddle} />
            {roomLinks}
          </div>
          {roomWritings}
        </div>
      </div>
    </div>
  )
}

export default Room
