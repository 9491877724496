import React, { useRef, useState } from "react"

import * as doorsStyles from "./doors.module.sass"

import LogoSVG from "../assets/images/doors/logo.svg"
import DoorClosedSVG from "../assets/images/doors/door.svg"
import DoorOpenSVG from "../assets/images/doors/door-open.svg"
import Link from "gatsby-link"

const Doors = (props) => {
  let logoRef = useRef(null)
  let logo = <div ref={logoRef} className={`${doorsStyles.logoImage}`}><LogoSVG/></div>

  let inactiveDoor = <div className={`${doorsStyles.doorImage} enter`}><DoorClosedSVG /></div>
  let closedDoorRef = useRef(null)
  // let closedDoor = <div ref={closedDoorRef} className={`${doorsStyles.doorImage} enter`} onMouseOver={fadeDoorOut} onAnimationEnd={animationEndHandler}><DoorClosedSVG /></div>
  let closedDoor = <div ref={closedDoorRef} className={`${doorsStyles.doorImage} enter`} onMouseOver={fadeDoorOut}><DoorClosedSVG /></div>

  let openDoorRef = useRef(null)
  // let openDoor = <Link ref={openDoorRef} className={`${doorsStyles.doorOpenImage}`} onMouseLeave={fadeLogoOut} to="room" onClick={props.exitTransition} onAnimationEnd={openAnimationEndHandler}><DoorOpenSVG /></Link>
  let openDoor = <Link ref={openDoorRef} className={`${doorsStyles.doorOpenImage}`} onMouseLeave={fadeLogoOut} to="room" onClick={props.exitTransition}><DoorOpenSVG /></Link>



  function animationEndHandler(e) {
    if (e.animationName.includes("out")) {
      fadeLogoIn()
    } else {
    }
  }

  function openAnimationEndHandler(e) {
    if (e.animationName.includes("out")) {
      fadeDoorIn()
    } else {
    }
  }

  function fadeDoorOut() {
    closedDoorRef.current.className = `${doorsStyles.doorImage} exit`
    fadeLogoIn()
  }

  function fadeLogoIn() {
    // closedDoorRef.current.className = `${doorsStyles.doorImage}`
    openDoorRef.current.className = `${doorsStyles.doorOpenImage} enter-slow`
    logoRef.current.className = `${doorsStyles.logoImage} enter`
  }

  function fadeLogoOut() {
    openDoorRef.current.className = `${doorsStyles.doorOpenImage} exit`
    logoRef.current.className = `${doorsStyles.logoImage} exit`
    fadeDoorIn()

  }

  function fadeDoorIn() {
    openDoorRef.current.className = `${doorsStyles.doorOpenImage}`
    logoRef.current.className = `${doorsStyles.logoImage}`
    closedDoorRef.current.className = `${doorsStyles.doorImage} enter`
  }


  return (
    <>
      <div className={doorsStyles.logoContainer}>
        {logo}
      </div>
      <div className={doorsStyles.doorsContainer}>
        {inactiveDoor}
        {inactiveDoor}
        {closedDoor}
        {openDoor}
      </div>
    </>
  )
}

export default Doors
