import React, { useEffect, useRef, useState } from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import * as cloakroomStyles from "./cloakroom.module.sass"

import VestiaireSVG from "../assets/images/title-vestiaire.svg"
import Link from "gatsby-link"


const Cloakroom = (props) => {
  const clothes = props.clothes

  let collectionsIDs = []
  let clothesPublished = []

  // function fadeWritingIn(object) {
  //   object.props.children[1].className = `${cloakroomStyles.clothUndertitle} enter`
  // }

  // function fadeWritingOut(object) {
  //   object.props.children[1].className = `${cloakroomStyles.clothUndertitle} exit`
  // }

  for (let i = 0; i < clothes.length; i++) {
    // if (clothes[i].clothCollection !== currentCollection) {
    //   console.log("not here")
    let collectionExists = false
    for (let j = 0; j < collectionsIDs.length; j++) {
      if (clothes[i].clothCollection == collectionsIDs[j]) {
        collectionExists = true
      }
    }
    if (!collectionExists) {
      collectionsIDs.push(clothes[i].clothCollection)
      //! super bad ID's, but normal should work hihi
      let newSubArray = [<h2 className={cloakroomStyles.collectionTitle}>{clothes[i].clothCollection}</h2>]
      clothesPublished.push(newSubArray)
    }
    // }
  }

  console.log("clothes length"+clothes.length)
  for (let i = 0; i < clothes.length; i++) {
    // let cloth = <Cloth key={i} cloth={clothes[i]}></Cloth>
    let collectionID = 0
    for (let j = 0; j < collectionsIDs.length; j++) {
      if (clothes[i].clothCollection == collectionsIDs[j]) {
        collectionID = j
        break
      }
    }
    console.log(clothes[i])
    let clothImage=null
    //verifying there is no null image
    for(let j=0; j<clothes[i].clothImage.length; j++){
        if(getImage(clothes[i].clothImage[j].clothImage)!==null && getImage(clothes[i].clothImage[j].clothImage)!==undefined){
          clothImage=getImage(clothes[i].clothImage[j].clothImage)
          break
        }
    }
    clothesPublished[collectionID].push(<Link to={"/" + clothes[i].clothURL} className={cloakroomStyles.collectionImage}><GatsbyImage key={i} alt={"Cloth Image"} image={clothImage}></GatsbyImage><h4 className={cloakroomStyles.clothUndertitle}>{clothes[i].clothTitle}</h4></Link>)
  }

  console.log(clothesPublished)

  return (
    <>
      <div className={cloakroomStyles.pageContent}>
        <h1>Vestiaire<VestiaireSVG className={cloakroomStyles.drawnTitle} /></h1>
        <div className={cloakroomStyles.collectionContent}>
          {clothesPublished}
        </div>
      </div>
    </>
  )
}
export default Cloakroom
