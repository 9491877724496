// extracted by mini-css-extract-plugin
export const pageContent = "cloakroom-module--page-content--2igzB";
export const drawnTitle = "cloakroom-module--drawn-title--jAxQv";
export const collectionContent = "cloakroom-module--collection-content--3QrWJ";
export const collectionTitle = "cloakroom-module--collection-title--2vuqO";
export const collectionImage = "cloakroom-module--collection-image--HFCGr";
export const clothUndertitle = "cloakroom-module--cloth-undertitle--1Ij3F";
export const cloakroomContent = "cloakroom-module--cloakroom-content--3yU3w";
export const clothTitle = "cloakroom-module--cloth-title--Uz2ri";
export const svgTitle = "cloakroom-module--svg-title--1w4JB";
export const clothImageContainer = "cloakroom-module--cloth-image-container--32HGx";
export const photoFrame = "cloakroom-module--photo-frame--2hwoR";
export const clothImage = "cloakroom-module--cloth-image--1QIKe";
export const clothImageHidden = "cloakroom-module--cloth-image-hidden--1ti82";
export const sideContainer = "cloakroom-module--side-container--1qLPc";
export const collectionContainer = "cloakroom-module--collection-container--e6H-g";
export const clothCollection = "cloakroom-module--cloth-collection--2hqqa";
export const movingArrow = "cloakroom-module--moving-arrow--29ioh";
export const slide = "cloakroom-module--slide--aV83b";
export const clothMiniatureContainer = "cloakroom-module--cloth-miniature-container--1ro72";
export const clothMiniature = "cloakroom-module--cloth-miniature--3ZPdd";
export const clothMiniatureSelector = "cloakroom-module--cloth-miniature-selector--1TNaF";
export const materialContainer = "cloakroom-module--material-container--2Dl96";
export const clothMaterial = "cloakroom-module--cloth-material--11CD_";
export const modelImageContainer = "cloakroom-module--model-image-container--1bLQc";
export const modelImage = "cloakroom-module--model-image--1h3QQ";
export const acquireContainerDesktop = "cloakroom-module--acquire-container-desktop--3gcJP";
export const clothDescription = "cloakroom-module--cloth-description--3DOZf";
export const acquireContainer = "cloakroom-module--acquire-container--26QMO";