import React, { useEffect, useRef, useState } from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Link from "gatsby-link"
import ReactMarkdown from "react-markdown"

import * as cloakroomStyles from "./cloakroom.module.sass"

import LineSVG from "../assets/images/line.svg"
import DescriptionSVG from "../assets/images/description.svg"
import MatiereEtDetailsSVG from "../assets/images/matieresEtDetails.svg"
import AcquerirSVG from "../assets/images/aquerir.svg"
import ArrowSVG from "../assets/images/arrow.svg"
import RetourVestiaireSVG from "../assets/images/retourVestiaire.svg"
import PhotoFrameSVG from "../assets/images/photoFrame.svg"


const Cloth = (props) => {
  const [pageWidth, setPageWidth] = useState(0)
  useEffect(() => {
    setPageWidth(window.innerWidth)
    window.addEventListener("resize", () => {
      setPageWidth(window.innerWidth)
    })
    return () => {
      window.removeEventListener("resize", () => {
      })
    }
  }, [])


  let clothMiniatures = []
  let clothImages = []
  const [displayedImageIndex, setDisplayedImageIndex] = useState(0)

  for (let j = 0; j < props.cloth.clothImage.length; j++) {
    let clothMiniature = <div key={j} className={cloakroomStyles.clothMiniature} onClick={(() => {
      setDisplayedImageIndex(j)
    })} onMouseOver={(() => {
      setDisplayedImageIndex(j)
    })}><GatsbyImage alt={"Cloth Image"} image={getImage(props.cloth.clothImage[j].clothImage)}></GatsbyImage>
      <LineSVG className={(displayedImageIndex == j ? `${cloakroomStyles.clothMiniatureSelector} enter` : `${cloakroomStyles.clothMiniatureSelector} exit`)} />
    </div>
    clothMiniatures.push(clothMiniature)
    let clothImage = <GatsbyImage key={j} alt={"Cloth Image"} image={getImage(props.cloth.clothImage[j].clothImage)} className={(displayedImageIndex == j ? `${cloakroomStyles.clothImage} enter` : `${cloakroomStyles.clothImageHidden} exit`)}></GatsbyImage>
    clothImages.push(clothImage)
  }

  let clothMiniaturesDesktop = pageWidth >= 720 ? <div className={cloakroomStyles.clothMiniatureContainer}>
    {clothMiniatures}
  </div> : undefined
  let clothMiniaturesMobile = pageWidth < 720 ? <div className={cloakroomStyles.clothMiniatureContainer}>
    {clothMiniatures}
  </div> : undefined

  let clothCollectionDesktop = pageWidth >= 720 ? <div className={cloakroomStyles.collectionContainer}>
    <DescriptionSVG className={cloakroomStyles.svgTitle} />
    <h4 className={cloakroomStyles.clothCollection}>{props.cloth.clothCollection}</h4>
    <ReactMarkdown className={cloakroomStyles.clothDescription}>{props.cloth.clothDescription}</ReactMarkdown>
  </div> : undefined
  let clothCollectionMobile = pageWidth < 720 ? <div className={cloakroomStyles.collectionContainer}>
    {/*<DescriptionSVG className={cloakroomStyles.svgTitle} />*/}
    <h4 className={cloakroomStyles.clothCollection}>{props.cloth.clothCollection}</h4>
  </div> : undefined

  let clothDescriptionMobile = pageWidth < 720 ? <ReactMarkdown className={cloakroomStyles.clothDescription}>{props.cloth.clothDescription}</ReactMarkdown> : undefined

  let aquireDesktop = pageWidth >= 720 ? <div className={cloakroomStyles.acquireContainerDesktop}>
    <Link to={props.cloth.shopifyLink} target={"_blank"}>
      <AcquerirSVG className={cloakroomStyles.svgTitle} />
    </Link>
  </div> : undefined
  let modelImages = []
  for (let j = 0; j < props.cloth.modelImage.length; j++) {
    let modelImage = <GatsbyImage key={j} alt={"Model Image"} image={getImage(props.cloth.modelImage[j].modelImage)} className={cloakroomStyles.modelImage}></GatsbyImage>
    modelImages.push(modelImage)
  }
  return (
    <>
    <div className={cloakroomStyles.pageContent}>
      <div className={cloakroomStyles.cloakroomContent}>
        <h2 className={cloakroomStyles.clothTitle}>{props.cloth.clothTitle}</h2>
        {clothCollectionMobile}
        <div className={cloakroomStyles.clothImageContainer}>
        {clothImages}
          <PhotoFrameSVG className={cloakroomStyles.photoFrame}>
          </PhotoFrameSVG>
        </div>
        {clothMiniaturesMobile}
        <div className={cloakroomStyles.sideContainer}>
          {clothCollectionDesktop}
          {clothDescriptionMobile}
          <Link to={props.cloth.shopifyLink} target={"_blank"} className={cloakroomStyles.acquireContainer}>
            <AcquerirSVG className={cloakroomStyles.svgTitle} />
            <ArrowSVG className={cloakroomStyles.movingArrow}></ArrowSVG>
          </Link>
          {clothMiniaturesDesktop}
        </div>
        <div className={cloakroomStyles.materialContainer}>
          <MatiereEtDetailsSVG className={cloakroomStyles.svgTitle} />
          <ReactMarkdown className={cloakroomStyles.clothMaterial}>{props.cloth.clothMaterial}</ReactMarkdown>
        </div>
        <div className={cloakroomStyles.modelImageContainer}>
          {modelImages}
        </div>
        {aquireDesktop}
      </div>
      {/*      <Link to={"/cloakroom"}>
        <RetourVestiaireSVG className={cloakroomStyles.svgTitle} />
      </Link>*/}
    </div>
    </>
  )
}
export default Cloth

