import React, { useEffect, useRef, useState } from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import ReactMarkdown from "react-markdown"
import PlayButton from "../components/playButton"
import Marquee from "react-double-marquee"

import * as soundStyles from "./sound.module.sass"

import SonSVG from "../assets/images/title-son.svg"
import SquareSVG from "../assets/images/square.svg"
import AlbumSVG from "../assets/images/album-fat.svg"
import ArtisteSVG from "../assets/images/artiste-fat.svg"
import DetailsSVG from "../assets/images/details-fat.svg"
import EnUnePhraseSVG from "../assets/images/enUnePhrase-fat.svg"


const Sound = (props) => {
  const sounds = props.sounds
  const currentSoundIndex = props.currentSoundIndex
  const setCurrentSoundIndex = props.setCurrentSoundIndex
  const audioPlayerRef = props.audioPlayerRef


  let songsPublished = []
  for (let i = sounds.length-1; i>=0 ; i--) {
    let soundLayedOut = <div key={i} className={soundStyles.soundContent}>

      <h2 className={soundStyles.songTitle}>{sounds[i].songTitle}</h2>
      {/*<div className={soundStyles.artworkContainer}>*/}
      <PlayButton songIndex={i} songArtwork={getImage(sounds[i].songArtwork)} currentSoundIndex={currentSoundIndex} setCurrentSoundIndex={setCurrentSoundIndex} audioPlayerRef={audioPlayerRef} />
      {/*  <SquareSVG className={(i == currentSoundIndex ? `${soundStyles.songArtworkSelector} enter` : `${soundStyles.songArtworkSelector} exit`)}></SquareSVG>*/}
      {/*  <GatsbyImage alt={"Song Artwork"} image={getImage(sounds[i].songArtwork)} className={soundStyles.songArtwork}></GatsbyImage>*/}
      {/*</div>*/}
      <div className={soundStyles.sideContainer}>
        <AlbumSVG className={soundStyles.svgTitle} />
        <div className={soundStyles.songAlbum}><Marquee direction="left" scrollWhen="overflow"><h4>{sounds[i].songAlbum}</h4></Marquee></div>
        <ArtisteSVG className={soundStyles.svgTitle} />
        <div className={soundStyles.songArtist}><Marquee direction="left" scrollWhen="overflow"><h4>{sounds[i].songArtist}</h4></Marquee></div>
        <DetailsSVG className={soundStyles.svgTitle} />
        <div className={soundStyles.songDetails}>
          {/* we had the speed and option here but was a problem with strings */}
          <Marquee direction="left" scrollWhen="overflow"><h4>{sounds[i].songDetails}</h4></Marquee>
      </div>
    </div>
    <div className={soundStyles.en1Phrase}><EnUnePhraseSVG className={soundStyles.en1PhraseSvg} /></div>
    <h5 className={soundStyles.songComment}>{sounds[i].songComment}</h5>
    {/*<PlayButton songIndex={i} currentSoundIndex={currentSoundIndex} setCurrentSoundIndex={setCurrentSoundIndex} audioPlayerRef={audioPlayerRef} />*/
    }
    <span className={soundStyles.descritptionIndent}></span><ReactMarkdown className={soundStyles.songDescription}>{sounds[i].songDescription}</ReactMarkdown>

  </div>

    songsPublished.push(soundLayedOut)
  }
  return (
    <>
      <div className={soundStyles.pageContent}>
        <h1>Son<SonSVG className={soundStyles.drawnTitle} /></h1>
        {songsPublished}
      </div>
    </>
  )
}
export default Sound
