// extracted by mini-css-extract-plugin
export const pageContent = "sound-module--page-content--1Fl8K";
export const drawnTitle = "sound-module--drawn-title--1uB1z";
export const soundContent = "sound-module--sound-content--34Tt7";
export const songTitle = "sound-module--song-title--3HeBX";
export const sideContainer = "sound-module--side-container---0hm8";
export const svgTitle = "sound-module--svg-title--369md";
export const songAlbum = "sound-module--song-album--2F3Iv";
export const songArtist = "sound-module--song-artist--3cQU3";
export const songDetails = "sound-module--song-details--3XYEd";
export const artworkContainer = "sound-module--artwork-container--39V40";
export const songArtworkSelector = "sound-module--song-artwork-selector--3ERTc";
export const songArtwork = "sound-module--song-artwork--ZKuaZ";
export const en1Phrase = "sound-module--en-1-phrase--3nOC2";
export const en1PhraseSvg = "sound-module--en-1-phrase-svg--4_OES";
export const songComment = "sound-module--song-comment--27wad";
export const songDescription = "sound-module--song-description--3EtPp";
export const descriptionIndent = "sound-module--description-indent--1-DPx";
export const playButton = "sound-module--play-button--2XEQ1";
export const circle = "sound-module--circle--39oQk";
export const playIcon = "sound-module--play-icon--1Ykl8";