import React, { useEffect, useRef, useState } from "react"
import { graphql } from "gatsby"

import AudioPlayer, { RHAP_UI } from "react-h5-audio-player"
import "../styles/playerStyles.css"

import * as MP3PlayerStyles from "./MP3Player.module.sass"


import PlaySVG from "../assets/images/player/playButton.svg"
import PauseSVG from "../assets/images/player/pauseButton.svg"
import NextSVG from "../assets/images/player/nextButton.svg"
import PrevSVG from "../assets/images/player/prevButton.svg"
import VolumeSVG from "../assets/images/player/volumeButton.svg"
import VolumeMuteSVG from "../assets/images/player/volumeButtonMute.svg"
import ProgressBarSVG from "../assets/images/player/progressBar.svg"
import ProgressBarEndSVG from "../assets/images/player/progressBarEnd.svg"
import ProgressBarStartSVG from "../assets/images/player/progressBarStart.svg"
import YouTubeSVG from "../assets/images/player/youtube-fatter.svg"
import ProgressBarIndicatorSVG from "../assets/images/player/progressBarIndicator.svg"


const MP3Player = (props) => {


  const sounds = props.sounds


  const audioPlayerRef = props.audioPlayerRef

  const [hasPaused, setHasPaused] = useState(false);

  function nextButtonHandler() {
    let nextSongIndex = ((props.currentSoundIndex + 1) < sounds.length ? props.currentSoundIndex + 1 : 0)
    props.setCurrentSoundIndex(nextSongIndex)
  }

  function previousButtonHandler() {
    let previousSongIndex = ((props.currentSoundIndex - 1) >= 0 ? props.currentSoundIndex - 1 : sounds.length - 1)
    props.setCurrentSoundIndex(previousSongIndex)
  }

  function youtubeButtonHandler(){
    audioPlayerRef.current.audio.current.pause()
  }

  function pauseButtonHandler(){
    setHasPaused(!hasPaused)
  }

  const [progressBarWidth, setProgressBarWidth] = useState(0)
  if (typeof window !== `undefined`) {
    window.addEventListener("resize", () => {
      if (props.location.pathname !== "/") {
        //setProgressBarWidth(document.getElementsByClassName("rhap_progress-container")[0].offsetWidth)
      }
    })
  }
  useEffect(()=>{
    try{
      if(!hasPaused){
        audioPlayerRef.current.audio.current.play()
      }
    }catch(error){
      // console.log(error)
    }
  })
  let audioplayer = (props.location.pathname !== "/" ? <AudioPlayer ref={audioPlayerRef}
                                                                    className={MP3PlayerStyles.MP3Player}
                                                                    src={sounds[props.currentSoundIndex].songMP3.publicURL}
                                                                    customProgressBarSection={
                                                                      [
                                                                        RHAP_UI.PROGRESS_BAR,
                                                                        <div className={"custom_progress-bar-container"} style={{ width: progressBarWidth + 15 + "px" }}><ProgressBarStartSVG className={"custom_progress-bar_start"} /><ProgressBarSVG className={"custom_progress-bar"} /><ProgressBarEndSVG className={"custom_progress-bar_end"} /></div>,
                                                                        RHAP_UI.VOLUME
                                                                      ]
                                                                    }
                                                                    customControlsSection={
                                                                      [
                                                                        RHAP_UI.MAIN_CONTROLS,
                                                                        <a className={"rhap_yt-button"} href={sounds[props.currentSoundIndex].youtubeLink} target={"_blank"} onClick={youtubeButtonHandler}><YouTubeSVG /></a>
                                                                      ]
                                                                    }
                                                                    layout={"horizontal-reverse"}
                                                                    showSkipControls={true}
                                                                    showJumpControls={false}
                                                                    showDownloadProgress={false}
                                                                    onPlay={null}
                                                                    onPause={pauseButtonHandler}
                                                                    onPlayError={e => console.log(e)}
                                                                    onClickNext={nextButtonHandler}
                                                                    onClickPrevious={previousButtonHandler}
                                                                    onEnded={nextButtonHandler}
                                                                    customIcons={{
                                                                      play: <PlaySVG />,
                                                                      pause: <PauseSVG />,
                                                                      next: <NextSVG />,
                                                                      previous: <PrevSVG />,
                                                                      volume: <VolumeSVG />,
                                                                      volumeMute: <VolumeMuteSVG />
                                                                    }}
                                                                    
  /> : undefined)


  return (
    <>
      {audioplayer}
    </>
  )
}
export default MP3Player
