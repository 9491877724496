
import React, { useEffect, useRef, useState } from "react"
import { graphql } from "gatsby"
import Helmet from "react-helmet"

import Header from "../components/header"
import Doors from "../components/doors"
import Room from "../components/room"
import { Router } from "@reach/router"
import Sound from "../components/sound"
import Cloakroom from "../components/cloakroom"
import Cloth from "../components/cloth"
import MP3Player from "../components/MP3Player"
import Contact from "../components/Contact"

const IndexPage = (props) => {
  const { allStrapiSound: { nodes: sounds } } = props.data
  const { allStrapiCloakroom: { nodes: clothes } } = props.data

  
  const [currentSoundIndex, setCurrentSoundIndex] = useState(sounds.length-1)
  const audioPlayerRef = useRef(null)

  let transitionContainerRef = useRef()

  function exitTransition() {
    transitionContainerRef.current.className = "transitionContainer exit-fast"
  }

  function enterTransition() {
    transitionContainerRef.current.className = "transitionContainer enter-slow"
  }

  function transitionAnimationEnd(e) {
    if (e.animationName.includes("out")) {
      enterTransition()
    } else {
    }
  }

  let clothesRoutes = []
  for (let i = 0; i < clothes.length; i++) {
    clothesRoutes.push(<Cloth key={clothes[i].id} path={"/" + clothes[i].clothURL} cloth={clothes[i]}></Cloth>)
    // console.log("/" + clothes[i].clothURL)
  }

  return (
    <>
      <Helmet>
        <title>{props.data.site.siteMetadata?.title || "3ème Chambre"}</title>
        <meta name="author" content={props.data.site.siteMetadata?.description} />
        <meta name="description" content={props.data.site.siteMetadata?.description} />
        <meta name="keywords" content={props.data.site.siteMetadata?.keywords} />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />

      </Helmet>
      <MP3Player location={props.location} audioPlayerRef={audioPlayerRef} currentSoundIndex={currentSoundIndex} setCurrentSoundIndex={setCurrentSoundIndex} sounds={sounds}></MP3Player>
      <div ref={transitionContainerRef} className={"transitionContainer enter-slow"} onAnimationEnd={(e) => {
        transitionAnimationEnd(e)
      }}>
        <Router basepath="/">
          <Doors path="/" exitTransition={exitTransition}></Doors>
          <Room path="/room" exitTransition={exitTransition} audioPlayerRef={audioPlayerRef}></Room>
          <Sound path="/sound" sounds={sounds} currentSoundIndex={currentSoundIndex} setCurrentSoundIndex={setCurrentSoundIndex} audioPlayerRef={audioPlayerRef}></Sound>
          <Cloakroom path="/cloakroom" clothes={clothes}></Cloakroom>
          <Contact path="/contact"></Contact>
          {clothesRoutes}
        </Router>
      </div>
    </>
  )

}
export default IndexPage

export const query = graphql`
query
{
  site
  {
    siteMetadata
    {
      title
      author
      description
      keywords
    }
  }
  allStrapiSound
  {
    nodes
    {
      strapiId
      id
      songTitle
      songArtist
      songAlbum
      songComment
      songDetails
      songDescription
      songArtwork
      {
        childImageSharp
        {
          gatsbyImageData
        }
      }
      youtubeLink
      songMP3
      {
        absolutePath
        publicURL
      }
    }
  }
  allStrapiCloakroom
  {
    nodes
    {
      strapiId
      id
      clothTitle
      clothCollection
      clothDescription
      clothURL
      clothImage{
        id
        clothImage {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
      clothMaterial
      modelImage{
        id
        modelImage {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
      shopifyLink
    }
  }
}
`
