import React, { useEffect, useRef, useState } from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import * as contactStyles from "./contact.module.sass"

import LogoSVG from "../assets/images/doors/logo.svg"


const Contact = (props) => {


  return (
    <>
      <div className={contactStyles.pageContent}>
        <LogoSVG className={contactStyles.drawnTitle}></LogoSVG>
        <div className={contactStyles.explanations}>
          <p>Nous. Partout. Pour toujours.
            <br/>
            Troisième chambre est un projet basé sur les vêtements et tout ce qu’ils transportent. L’authenticité, l’originalité et la qualité sont les bases sur lesquels sont créés les produits de la marque.
            <br/>
            <br/>
            Us. Everywhere. Forever.
            <br/>
            Troisième Chambre is a project based on clothing and everything they transmit.
            Authenticity, originality and quality are the base on which the product of the brand are created. <br/><br/></p>
            <br/>
          <h4>Nyko Egger</h4>
          <a href="mailto:troisi3mechambre@gmail.com"><h4>troisi3mechambre@gmail.com</h4></a>
        </div>
      </div>
    </>
  )
}
export default Contact
